<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Skladdan chiqimlar registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  :error-messages="reg_nomerErrors"
                  v-model="reg_nomer"
                  label="Registratsiya raqami"
                  outlined
                  dense
                  :success="reg_nomer !== ''"
                  @input="$v.reg_nomer.$touch()"
                  @blur="$v.reg_nomer.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="oper_dateErrors"
                      label="Sana"
                      readonly
                      outlined
                      :value="oper_date | dateFilter"
                      v-bind="attrs"
                      append-icon="event"
                      v-on="on"
                      dense
                      :success="oper_date !== ''"
                      @input="$v.oper_date.$touch()"
                      @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="oper_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-select
                  @change="getExportedWarehouse(exported_warehouse.id)"
                  :error-messages="exported_warehouseErrors"
                  v-model="exported_warehouse"
                  :items="allSklads"
                  :success="exported_warehouse !== ''"
                  item-text="sklad_name"
                  item-value="value"
                  return-object
                  outlined
                  dense
                  label="Sklad"
                  :loading="loading.allskladlist"
                  @input="$v.exported_warehouse.$touch()"
                  @blur="$v.exported_warehouse.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="12" />
              <v-col cols="4" class="pl-0 py-0">
                <v-combobox
                  :error-messages="mainExpErrors"
                  @change="change1"
                  :items="mainExpenditures"
                  :success="
                    mainExp !== '' && mainExp !== null && mainExp !== undefined
                  "
                  v-model="mainExp"
                  item-text="name"
                  item-value="id"
                  label="Asosiy xarajatlar"
                  outlined
                  dense
                  :loading="loading.mainExpenditures"
                  @input="$v.mainExp.$touch()"
                  @blur="$v.mainExp.$touch()"
                ></v-combobox>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-combobox
                  :error-messages="expCategoryErrors"
                  @change="change2"
                  :items="category"
                  v-model="expCategory"
                  :success="expCategory != ''"
                  item-text="name"
                  item-value="id"
                  dense
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                  @input="$v.expCategory.$touch()"
                  @blur="$v.expCategory.$touch()"
                />
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-combobox
                  :error-messages="expSet1Errors"
                  :items="category2"
                  v-model="expSet1"
                  :success="
                    expSet1 !== '' && expSet1 !== null && expSet1 !== undefined
                  "
                  no-data-text="Malumot topilmadi"
                  :hide-no-data="true"
                  dense
                  item-text="name"
                  item-value="id"
                  label="Kategoriya2"
                  outlined
                  @input="$v.expSet1.$touch()"
                  @blur="$v.expSet1.$touch()"
                ></v-combobox>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  :error-messages="commentErrors"
                  v-model="comment"
                  :success="comment !== ''"
                  name="input-7-1"
                  label="Comment"
                  outlined
                  @input="$v.comment.$touch()"
                  @blur="$v.comment.$touch()"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pl-0 pb-2">
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-autocomplete
                      v-model="productsForExport"
                      :items="storedproductsForExport"
                      item-text="name"
                      item-value="id"
                      label="Nomenklatura"
                      outlined
                      class="mx-1"
                      return-object
                      dense
                      style="width: 39%"
                      :loading="loading.products"
                    />
                    <v-text-field
                      disabled
                      v-if="productCondition == null"
                      :value="productCondition"
                      label="Holat"
                      outlined
                      dense
                      class="mx-1"
                      style="width: 15%"
                    ></v-text-field>
                    <v-text-field
                      v-model="amount"
                      label="Soni"
                      outlined
                      v-mask="'#####'"
                      :error="amount > limit"
                      :error-messages="
                        amount > limit ? 'xato max:' + limit : ''
                      "
                      dense
                      class="mx-1"
                      style="width: 8%"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="price | mask"
                      label="Narxi"
                      outlined
                      dense
                      class="mx-1"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * price || '' | mask"
                      label="Jami"
                      outlined
                      dense
                      class="mx-1"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * ndsPerItem || '' | mask"
                      label="Nds summa"
                      outlined
                      dense
                      class="mx-1"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * ndsPerItem + amount * price || '' | mask"
                      label="Summa(NDS)"
                      outlined
                      dense
                      class="mx-1"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row align="center" v-show="isExistCategory">
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-autocomplete
                      v-model="optionalProps.data.truck_props"
                      :items="truckList"
                      label="Mashina"
                      outlined
                      class="mx-1"
                      dense
                      :loading="loading.trucklist"
                      @change="onTruckSelected"
                      @input="$v.optionalProps.data.truck_props.$touch()"
                      @blur="$v.optionalProps.data.truck_props.$touch()"
                      :error-messages="
                        !$v.optionalProps.data.truck_props.required
                          ? [fieldMustBeFilledText]
                          : []
                      "
                      :success="
                        optionalProps.data.truck_props &&
                        optionalProps.data.truck_props.id
                      "
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.truck_type.brand.name }}-{{
                          item.truck_type.model
                        }}-{{ item.reg_number_region }} {{ item.reg_number }}
                      </template>
                      <template slot="item" slot-scope="{ item }">
                        {{ item.truck_type.brand.name }}-{{
                          item.truck_type.model
                        }}-{{ item.reg_number_region }} {{ item.reg_number }}
                      </template>
                    </v-autocomplete>

                    <template v-if="importCategoryCode != '7000'">
                      <v-text-field
                        v-model.number="optionalProps.data.fields.start_usage"
                        label="Boshlanish(km)"
                        outlined
                        dense
                        class="mx-1"
                        type="number"
                        @input="onStartUsageChange"
                        :error-messages="
                          !$v.optionalProps.data.fields.start_usage.required
                            ? [fieldMustBeFilledText]
                            : []
                        "
                        :success="optionalProps.data.fields.start_usage"
                        @blur="
                          $v.optionalProps.data.fields.start_usage.$touch()
                        "
                      />
                    </template>

                    <v-text-field
                      v-model="optionalProps.data.fields.normative"
                      label="Normativ"
                      outlined
                      dense
                      class="mx-1"
                      @input="onNormativeChange"
                      :success="optionalProps.data.fields.normative"
                      @blur="$v.optionalProps.data.fields.normative.$touch()"
                      :error-messages="
                        !$v.optionalProps.data.fields.normative.required
                          ? [fieldMustBeFilledText]
                          : []
                      "
                    />

                    <template v-if="importCategoryCode != '7000'">
                      <v-text-field
                        v-model="optionalProps.data.fields.end_usage"
                        label="Tugash(km)"
                        outlined
                        dense
                        class="mx-1"
                        type="number"
                        :success="optionalProps.data.fields.end_usage"
                        @input="$v.optionalProps.data.fields.end_usage.$touch()"
                        @blur="$v.optionalProps.data.fields.end_usage.$touch()"
                        :error-messages="
                          !$v.optionalProps.data.fields.end_usage.required
                            ? [fieldMustBeFilledText]
                            : []
                        "
                      />
                    </template>

                    <template v-if="importCategoryCode == '7000'">
                      <v-menu
                        v-model="optionalProps.data.menu.start_date_usage"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Sanadan"
                            readonly
                            outlined
                            :value="
                              optionalProps.data.fields.start_date_usage
                                | dateFilter
                            "
                            v-bind="attrs"
                            append-icon="event"
                            v-on="on"
                            dense
                            :success="
                              optionalProps.data.fields.start_date_usage
                            "
                            @input="
                              $v.optionalProps.data.fields.start_date_usage.$touch()
                            "
                            @blur="
                              $v.optionalProps.data.fields.start_date_usage.$touch()
                            "
                            :error-messages="
                              !$v.optionalProps.data.fields.start_date_usage
                                .required
                                ? [fieldMustBeFilledText]
                                : []
                            "
                            class="mx-1"
                          />
                        </template>
                        <v-date-picker
                          v-model="optionalProps.data.fields.start_date_usage"
                          @input="
                            ;(optionalProps.data.menu.start_date_usage = false),
                              recalcEndDateUsage(null)
                          "
                        />
                      </v-menu>

                      <v-menu
                        v-model="optionalProps.data.menu.end_date_usage"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        disabled
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Sanagacha"
                            readonly
                            outlined
                            :value="
                              optionalProps.data.fields.end_date_usage
                                | dateFilter
                            "
                            v-bind="attrs"
                            append-icon="event"
                            v-on="on"
                            dense
                            :success="optionalProps.data.fields.end_date_usage"
                            @input="
                              $v.optionalProps.data.fields.end_date_usage.$touch()
                            "
                            @blur="
                              $v.optionalProps.data.fields.end_date_usage.$touch()
                            "
                            :error-messages="
                              !$v.optionalProps.data.fields.end_date_usage
                                .required
                                ? [fieldMustBeFilledText]
                                : []
                            "
                            class="mx-1"
                          />
                        </template>
                        <v-date-picker
                          v-model="optionalProps.data.fields.end_date_usage"
                          @input="
                            optionalProps.data.menu.end_date_usage = false
                          "
                        />
                      </v-menu>
                    </template>
                  </v-col>
                </v-row>

                <!-- <pre>{{
                  (productsForExport && importCategoryCode) || 'N/A'
                }}</pre> -->
                <div>
                  <v-row justify="end" class="text-right">
                    <v-col>
                      <v-btn @click="remove" color="error" class="mx-1" dense
                        >O'chirish</v-btn
                      >
                      <v-btn @click="add" dense color="success">Qo'shish</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  v-if="items.length"
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item.price | mask }}
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    {{ item.total | mask }}
                  </template>
                  <template v-slot:[`item.nds`]="{ item }">
                    {{ item.nds | mask }}
                  </template>
                  <template v-slot:[`item.nds_summa`]="{ item }">
                    {{ item.nds_summa | mask }}
                  </template>
                  <template v-slot:[`item.total_withnds_summa`]="{ item }">
                    {{ item.total_withnds_summa | mask }}
                  </template>

                  <template v-slot:[`item.truck_id`]="{ item }">
                    {{ item.truck_full_name }}
                  </template>
                  <template v-slot:[`item.start_usage`]="{ item }">
                    {{
                      item.truck_expenditure_info &&
                      item.truck_expenditure_info.start_usage
                    }}
                  </template>

                  <template v-slot:[`item.normative`]="{ item }">
                    {{
                      item.truck_expenditure_info &&
                      item.truck_expenditure_info.normative
                    }}
                  </template>

                  <template v-slot:[`item.end_usage`]="{ item }">
                    {{
                      item.truck_expenditure_info &&
                      item.truck_expenditure_info.end_usage
                    }}
                  </template>

                  <template v-slot:[`item.start_date_usage`]="{ item }">
                    {{
                      item.truck_expenditure_info &&
                      item.truck_expenditure_info.start_date_usage | dateFilter
                    }}
                  </template>
                  <template v-slot:[`item.end_date_usage`]="{ item }">
                    {{
                      item.truck_expenditure_info &&
                      item.truck_expenditure_info.end_date_usage | dateFilter
                    }}
                  </template>
                </v-data-table>

                <!-- <div v-for="(v, index) in $v.$each.$iter" :key="index">
                  {{ v }}
                </div> -->
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newSkaladLoading"
              >
                <i v-if="newSkaladLoading" class="el-icon-loading"></i>Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { CurrencyDirective } from 'vue-currency-input'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required, requiredIf } from 'vuelidate/lib/validators'

const fieldMustBeFilledText = "To'ldirilishi shart bo'lgan qator"
const optionalDefaultFields = () => ({
  start_usage: null,
  end_usage: null,
  normative: null,
  start_date_usage: null,
  end_date_usage: null
})

export default {
  directives: { currency: CurrencyDirective },
  data() {
    return {
      fieldMustBeFilledText,
      newSkaladLoading: false,
      category: [],
      category2: [],
      seller_to_corpcard_cash: '',
      responsible: '',
      show: false,
      value: null,
      placement_type: '',
      mainExp: '',
      expCategory: '',
      expSet1: '',
      headers: [
        { text: 'Nomenklatura', value: 'name' },
        { text: 'Holati', value: 'condition' },
        { text: 'soni', value: 'amount' },
        { text: 'Narxi', value: 'price' },
        { text: 'Summa', value: 'total' },
        { text: 'NDS summa', value: 'nds_summa' },
        { text: 'Summa(NDS)', value: 'total_withnds_summa' },

        //optionsl props
        { text: 'Mashina', value: 'truck_id' },
        { text: 'Boshlanish(km)', value: 'start_usage' },
        { text: 'Normative', value: 'normative' },
        { text: 'Tugash(km)', value: 'end_usage' },
        { text: 'Sanadan', value: 'start_date_usage' },
        { text: 'Sanagacha', value: 'end_date_usage' }
      ],
      categoryId: null,
      tab: '',
      menu2: false,
      oper_date: '',
      reg_nomer: '',
      items: [],
      import_group_id: '',
      product_condition: '',
      productsForExport: '',
      productCondition: '',
      price: '',
      amount: '',
      ndsPerItem: '',
      comment: '',
      exported_warehouse: '',
      limit: 0,
      ndsSumma: 0,

      optionalProps: {
        truck_expenditure_info: {
          truck_id: null,
          start_usage: null,
          normative: null,
          end_usage: null,
          start_date_usage: null,
          end_date_usage: null
        },

        data: {
          truck_props: null,

          fields: { ...optionalDefaultFields() },

          menu: {
            start_usage_date: false,
            end_usage_date: false
          }
        }
      },

      importCategoriesWithOptionalPropsList: [5000, 6000, 7000, 8000],

      truckList: [],
      truckNormativeList: [],

      loading: {
        trucklist: false,
        allskladlist: false,
        mainExpenditures: false,
        products: false
      }
    }
  },
  validations: {
    oper_date: {
      required
    },

    reg_nomer: {
      required
    },
    exported_warehouse: {
      required
    },

    mainExp: {
      required
    },
    expCategory: {
      required
    },

    expSet1: {
      required
    },

    comment: {
      required
    },

    optionalProps: {
      data: {
        truck_props: {
          required
        },
        fields: {
          start_usage: {
            required: requiredIf(function () {
              return this.isExistCategory && this.importCategoryCode != '7000'
            })
          },

          end_usage: {
            required: requiredIf(function () {
              return this.isExistCategory && this.importCategoryCode != '7000'
            })
          },

          start_date_usage: {
            required: requiredIf(function () {
              return this.importCategoryCode == '7000'
            })
          },

          end_date_usage: {
            required: requiredIf(function () {
              return this.importCategoryCode == '7000'
            })
          },

          normative: {
            required: requiredIf(function () {
              return this.isExistCategory
            })
          }
        }
      }
    }
  },
  beforeMount() {
    this.toggeLoaderByKey(
      this.$store.dispatch('getAllSkladList'),
      'allskladlist'
    )

    this.toggeLoaderByKey(
      this.$store.dispatch('mainExpenditures'),
      'mainExpenditures'
    )
    this.getCategoryGroupNames()
  },
  watch: {
    mainExp(val) {
      this.category = val.expenditurecategory_set
    },
    expCategory(val) {
      if (val) this.category2 = val.expendituretype_set
    },
    productsForExport(val) {
      this.price = ''
      if (val) {
        this.amount = ''
        this.limit = val.remain_amount_in_warehouse
        if (val.product_condition) {
          this.productCondition = val.product_condition.name
        } else {
          this.product_condition = ''
        }
        this.price = val.price
        this.ndsPerItem = val.nds_per_item

        const f = this.truckNormativeList.find(
          (item) => item.code == this.importCategoryCode
        ) || { value: this.optionalProps.data.fields.normative }

        this.optionalProps.data.fields.normative =
          (this.importCategoriesWithOptionalPropsList.includes(
            +this.importCategoryCode
          ) &&
            f &&
            f.value) ||
          null

        this.resetOptionalProps()

        // to recalc by mashina
        this.onNormativeChange()

        // on nomenklatura change reset start usage
        this.onTruckSelected()
      }
    }
  },
  computed: {
    mainExpenditures() {
      return this.$store.state.requests.mainExpenditures
    },
    ...mapGetters(['allCategoryGroupNames', 'getCategoryGroupData']),
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    storedproductsForExport() {
      const data = this.$store.state.requests.storedproductsForExport
      data.forEach((x) => {
        x.name =
          x.import_category.name +
          ' - kirim narxi: ' +
          x.price +
          ' - ' +
          this.calcAmount(x)
      })
      return data
    },
    commentErrors() {
      const errors = []
      if (!this.$v.comment.$dirty) return errors

      !this.$v.comment.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expSet1Errors() {
      const errors = []
      if (!this.$v.expSet1.$dirty) return errors

      !this.$v.expSet1.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expCategoryErrors() {
      const errors = []
      if (!this.$v.expCategory.$dirty) return errors

      !this.$v.expCategory.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainExpErrors() {
      const errors = []
      if (!this.$v.mainExp.$dirty) return errors
      !this.$v.mainExp.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    exported_warehouseErrors() {
      const errors = []
      if (!this.$v.exported_warehouse.$dirty) return errors
      !this.$v.exported_warehouse.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors
      !this.$v.reg_nomer.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      if (data && data.length) {
        data.forEach((element) => {
          if (element.warehouse_responsible) {
            if (element.responsible_description) {
              element.sklad_name =
                element.name +
                ' | ' +
                element.responsible_description.staffpersonal_description
                  .first_name +
                ' ' +
                element.responsible_description.staffpersonal_description
                  .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        return data
      } else return data
    },

    isExistCategory() {
      return this.importCategoriesWithOptionalPropsList.includes(
        this.productsForExport && +this.importCategoryCode
      )
    },
    importCategoryCode() {
      return (
        this.productsForExport &&
        this.productsForExport.import_category &&
        this.productsForExport.import_category.code
      )
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    },
    floatToNumber: (val) => {
      return Math.floor(val)
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Skladdan chiqimlar registratsiyasi' }
    ])

    await this.toggeLoaderByKey(
      this.fetchTrucks().then((res) => {
        this.truckList = res || []
      }),
      'trucklist'
    )

    this.getTruckNormativeList().then((res) => {
      this.truckNormativeList = (res && res.data) || []
    })
  },
  methods: {
    ...mapActions([
      'fetchTrucks',
      'getTruckNormativeList',
      'getStoredproductsForExport',
      'getCategoryGroupNames'
    ]),

    resetOptionalProps() {
      this.optionalProps.data.fields = {
        ...optionalDefaultFields(),
        normative: this.optionalProps.data.fields.normative
      }
    },

    recalcEndDateUsage() {
      const date =
        (this.optionalProps.data.fields.start_date_usage &&
          new Date(this.optionalProps.data.fields.start_date_usage)) ||
        new Date()

      let newDate = new Date(
        date.setMonth(
          date.getMonth() + (+this.optionalProps.data.fields.normative || 0)
        )
      )

      this.optionalProps.data.fields.end_date_usage = newDate
        .toISOString()
        .slice(0, 10)
    },

    onTruckSelected(arg) {
      if (this.optionalProps) {
        if (arg && arg.id) {
          this.resetOptionalProps()
        }
        if (this.importCategoryCode != '7000') {
          const {
            data: { truck_props }
          } = this.optionalProps

          const odometr = truck_props && truck_props.odometr
          this.optionalProps.data.fields.start_usage = +odometr || ''

          this.recalcEndUsage()
        }
      }
    },

    onNormativeChange() {
      if (this.importCategoryCode == '7000') {
        this.recalcEndDateUsage()
      } else {
        this.recalcEndUsage()
      }
      this.$v.optionalProps.data.fields.normative.$touch()
    },

    onStartUsageChange() {
      this.recalcEndUsage()
      this.$v.optionalProps.data.fields.end_usage.$touch()
    },

    recalcEndUsage() {
      this.optionalProps.data.fields.end_usage =
        (+this.optionalProps.data.fields.normative || 0) +
          (+this.optionalProps.data.fields.start_usage || 0) || 0

      this.$v.optionalProps.data.fields.end_usage.$touch()
    },

    toggeLoaderByKey(callback, loaderKey) {
      if (this.loading[loaderKey]) {
        this.loading[loaderKey] = true

        try {
          return callback.then(() => (this.loading[loaderKey] = false))
        } catch (err) {
          this.loading[loaderKey] = false
        }
      }
    },

    calcAmount(x) {
      let totalAmount = this.items
        .filter((item) => item.id == x.id)
        .map((x) => x.amount)
        .reduce((a, b) => a + b, 0)
      if (this.items.find((item) => item.id == x.id)) {
        const amount = x.remain_amount_in_warehouse - totalAmount
        // console.log(x.remain_amount_in_warehouse, totalAmount)
        this.limit = amount
        return amount
      } else {
        this.limit = x.remain_amount_in_warehouse
        return x.remain_amount_in_warehouse
      }
    },
    getExportedWarehouse(payload) {
      this.productsForExport = ''
      this.amount = ''
      this.price = ''
      this.ndsPerItem = 0
      this.limit = 0
      this.productCondition = ''
      this.toggeLoaderByKey(
        this.getStoredproductsForExport(payload),
        'products'
      )
    },
    change1() {
      this.expCategory = ''
      this.expSet1 = ''
    },
    change2() {
      this.expSet1 = ''
    },
    remove() {
      this.items.reverse()
      if (this.items.length == 0) {
        Swal.fire({
          title: "Malumotlar to'liq emas",
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-error'
        })
      }
      if (this.items.length == 2) {
        this.items = []
        this.ndsSumma = 0
      }
      if (this.items.length !== 0) {
        this.ndsSumma -= this.items[this.items.length - 1].total_withnds_summa
        this.items[0].total_withnds_summa -=
          this.items[this.items.length - 1].total_withnds_summa
        this.items.splice(-1, 1)
      }
      this.items.reverse()
    },
    add() {
      if (this.isExistCategory) {
        // Validation for import category code where not 7000
        if (this.importCategoryCode != '7000') {
          const requiredKeys = ['start_usage', 'normative', 'end_usage']
          if (
            requiredKeys.some(
              (key) => !this.$v.optionalProps.data.fields[key].required
            )
          ) {
            Swal.fire({
              title: "Malumotlar to'liq emas",
              text: "Boshlanish km, Normativ va Tugash km ko'rsatish zarur!",
              icon: 'error',
              confirmButtonClass: 'btn btn-error'
            })
            return
          }
        }

        if (this.importCategoryCode == '7000') {
          const requiredKeys = [
            'start_date_usage',
            'normative',
            'end_date_usage'
          ]
          if (
            requiredKeys.some(
              (key) => !this.$v.optionalProps.data.fields[key].required
            )
          ) {
            Swal.fire({
              title: "Malumotlar to'liq emas",
              text: "Normativ, Sanadan va Sanagacha ko'rsatish zarur!",
              icon: 'error',
              confirmButtonClass: 'btn btn-error'
            })
            return
          }
        }

        if (!this.$v.optionalProps.data.truck_props.required) {
          Swal.fire({
            title: "Malumotlar to'liq emas",
            text: 'Mashinani tanlang!',
            icon: 'error',
            confirmButtonClass: 'btn btn-error'
          })
          return
        }
      }

      if (!this.amount) {
        Swal.fire({
          title: "Malumotlar to'liq emas",
          text: 'Nomenklatura sonini kiriting!',
          icon: 'error',
          confirmButtonClass: 'btn btn-error'
        })
        return
      }

      if (this.productsForExport && this.amount) {
        if (this.amount <= this.limit) {
          let data = {
            id: this.productsForExport.id,
            name: this.productsForExport.name,
            condition: this.productCondition,
            import_group_id: this.productsForExport.import_group.id,
            import_category_id: this.productsForExport.import_category.id,
            exported_product_id: this.productsForExport.id,
            amount: parseInt(this.amount, 10),
            price: this.price,
            total: this.amount * this.price,
            nds: this.productsForExport.nds,
            // truck_id: null,
            nds_per_item: (this.productsForExport.nds * this.price) / 100,
            nds_summa: this.amount * this.ndsPerItem,
            total_withnds_summa:
              this.amount * this.ndsPerItem + this.amount * this.price,
            truck_full_name: `${this.optionalProps.data.truck_props.truck_type.brand.name}-${this.optionalProps.data.truck_props.truck_type.model} ${this.optionalProps.data.truck_props.reg_number_region} ${this.optionalProps.data.truck_props.reg_number}`
          }
          data.name = data.name.substring(0, data.name.indexOf('narxi'))

          if (this.isExistCategory) {
            data = {
              ...data,
              truck_expenditure_info: {
                ...this.optionalProps.data.fields,
                truck_id: this.optionalProps.data.truck_props.id
              }
            }
          }

          this.ndsSumma += data.total_withnds_summa
          this.items.reverse()
          if (this.items.length == 0) {
            const data = {
              name: 'Umumiy summa',
              total_withnds_summa: 0
            }
            this.items.push(data)
          }
          this.items.push(data)
          this.items[0].total_withnds_summa = this.ndsSumma
          this.items.reverse()
          this.products_services = {}
          this.productsForExport = ''
          this.amount = ''

          this.optionalProps.data.fields = {
            ...optionalDefaultFields()
          }
        }
      } else {
        Swal.fire({
          title: "Malumotlar to'liq emas",
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-error'
        })
      }
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          document: {
            operation_type: 'EXPORT',
            oper_date: this.oper_date,
            reg_number: this.reg_nomer,
            comment: this.comment,
            placement_expenditure: this.expSet1.id,
            exported_warehouse: this.exported_warehouse.id
          }
        }
        if (this.items.length) {
          const cloneItems = this.items.map(
            // eslint-disable-next-line no-unused-vars
            ({ id, name, condition, truck_full_name, ...props }) => props
          )
          cloneItems.splice(-1, 1)
          data.exported_products = [...cloneItems]
          this.newSkaladLoading = true
          this.$store
            .dispatch('createGoodsservicesExportOptional', data)
            .then(() => {
              this.newSkaladLoading = false
              this.$v.$reset()
            })
            .catch((err) => {
              this.newSkaladLoading = false
              console.error(err)
            })
        } else {
          Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      }
    },

    errorResultByFieldKey(fieldKey) {
      if (!this.$v[fieldKey].$dirty) return []

      this.$v[fieldKey].$touch()

      return (
        (!this.$v[fieldKey].required && [
          "To'ldirilishi shart bo'lgan qator"
        ]) ||
        []
      )
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
.theme--light >>> .v-input--is-disabled input {
  font-size: 9px !important;
}

.v-input__control {
  min-height: 30px !important;
}
</style>
